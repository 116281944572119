import React from 'react'
import { Button, Col, Input, Label, Row } from 'reactstrap'

function Filters({ params, handleSearch, handleSearchChange }) {

  function handleKeyDown(event) {
    if (event.key === "Enter")
      handleSearch()
  }

  return (
    <div className="mb-4">
      <Row>
        <Col sm="2">
          <Label className="form-label">Project Name</Label>
          <Input
            name="search"
            onKeyDown={handleKeyDown}
            type="text"
            onChange={handleSearchChange}
            value={params.search}
          />
        </Col>

        <Col sm="2">
          <Label className="form-label">Name</Label>
          <Input
            name="full_name"
            onKeyDown={handleKeyDown}
            type="text"
            onChange={handleSearchChange}
            value={params.full_name}
          />
        </Col>

        <Col sm="4">
          <div className="mt-4">
            <Button
              type="button"
              color="success"
              className="btn-rounded"
              onClick={handleSearch}
            >
              <i className="bx bx-search-alt me-1" />
              Search
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Filters
