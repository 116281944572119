import React, { useEffect, useState, useRef, Fragment } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import * as moment from "moment";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap";
import { Menu, MenuItem } from '@szhsin/react-menu';

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../../components/Common/DeleteModal";
import {
  getTimesheetReports as onGetTimesheetReports,
  deleteTimesheetReport as onDeleteTimesheetReport,
  deleteTimesheetReportModal,
  generateTimesheetReport as onGenerateTimesheetReport,
  generateTimesheetReportModal,
  setGeneratableTimesheetReport,
  editTimesheetReportModal,
  newTimesheetReportModal
} from "store/timesheetReports/actions";

import Create from './Create'
import Edit from './Edit'

//redux
import { useSelector, useDispatch } from "react-redux";
import { setLoader } from "utils/common";
import GenerateTimesheetReport from './GenerateTimesheetReport'
import { useHistory } from "react-router-dom";
import Filters from "./Filters";

const TimesheetReports = () => {
  const dispatch = useDispatch();

  const { timesheetReportWithPagy, loading, deleteModal, generateModal, generatableTimesheetReport } = useSelector(state => ({
    timesheetReportWithPagy: state.timesheetReports.timesheetReportWithPagy,
    loading: state.timesheetReports.loading,
    deleteModal: state.timesheetReports.delete,
    generateModal: state.timesheetReports.generate,
    generatableTimesheetReport: state.timesheetReports.generatableTimesheetReport,
  }));
  let { results: timesheetReports } = timesheetReportWithPagy

  const [timesheetReport, setTimesheetReport] = useState(null);
  const [searchParams, setSearchParams] = useState({
    search: '',
    full_name: '',
  })

  //pagination customization
  const pageOptions = {
    sizePerPage: timesheetReportWithPagy.page_size,
    totalSize: timesheetReportWithPagy.total,
    page: timesheetReportWithPagy.page,
    custom: true,
  };

  const COLS = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      text: "#",
      dataField: "index",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row, rowIndex) => <>{rowIndex + 1}</>,
    },
    {
      text: "Projects",
      dataField: "projects",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <b><i>{`${row.projects.length == 0 ? "N/A" : row.projects[0]?.project_name}` || 'N/A'}{row.projects.length > 1 ? "..." : ""}</i></b>
    },
    {
      text: "Subcontractors",
      dataField: "users",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <b><i>{row.users.length == 0 ? "N/A" : `${row.users[0]?.profile.first_name} ${row.users[0]?.profile.last_name}` || 'N/A'}{row.users.length > 1 ? "..." : ""}</i></b>
    },
    {
      dataField: "from_date",
      text: "From Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.from_date) || 'N/A',
    },
    {
      dataField: "to_date",
      text: "To Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.to_date) || 'N/A',
    },
    {
      dataField: "total_hours",
      text: "Total Hours",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => row.total_hours + " hours",
    },
    {
      dataField: "print",
      text: "Print",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, timesheetReport) => <Fragment>
        {
          timesheetReport.pdf_file ?
            <a href={timesheetReport.pdf_file} rel="noopener noreferrer" target={"_blank"} className="me-1">
              <i className="fas fa-print text-secondary font-size-18" />
            </a>
            :
            <i onClick={() => {
              dispatch(setGeneratableTimesheetReport(timesheetReport))
              dispatch(generateTimesheetReportModal())
            }} className="fas fa-print text-secondary font-size-18 me-1" />
        }
      </Fragment>
    },
    {
      dataField: "menu",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, timesheetReport) => (
        <Menu menuButton={<i className="mdi mdi-dots-horizontal font-size-18" />} transition>
          <MenuItem
            onClick={() => handleEditTimesheetReport(timesheetReport)}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => onClickDelete(timesheetReport)}
          >
            Delete
          </MenuItem>
        </Menu>
      ),
    },
  ];


  var node = useRef();

  //delete timesheetReport

  const onClickDelete = (timesheetReport) => {
    setTimesheetReport(timesheetReport);
    dispatch(deleteTimesheetReportModal())
  };

  const handleDeleteTimesheetReport = () => {
    dispatch(onDeleteTimesheetReport(timesheetReport))
  }

  const handleGenerateTimesheetReport = () => {
    dispatch(onGenerateTimesheetReport(generatableTimesheetReport))
  }

  const { SearchBar } = Search;

  function init() {
    dispatch(onGetTimesheetReports(true));
  }

  useEffect(() => {
    init()
  }, []);

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  const history = useHistory()

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page }) => {
    if (type == "pagination")
      dispatch(onGetTimesheetReports(true, { page, ...searchParams }));
  };

  const handleCreateTimesheetReport = () => {
    dispatch(newTimesheetReportModal())
  };

  const handleEditTimesheetReport = timesheetReport => {
    setTimesheetReport(timesheetReport)
    dispatch(editTimesheetReportModal())
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  /** set Date formate */
  const handleValidDate = date => {
    const date1 = date ? moment(new Date(date)).format("DD MMM Y") : "N/A";
    return date1;
  };

  let handleSearch = () => {
    dispatch(onGetTimesheetReports(false, searchParams));
  }

  let handleSearchChange = (e) => {
    setSearchParams({ ...searchParams, [e.target.name]: e.target.value })
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteTimesheetReport}
        onCloseClick={() => dispatch(deleteTimesheetReportModal())}
      />

      <Create />
      <Edit timesheetReport={timesheetReport} />

      <GenerateTimesheetReport
        show={generateModal}
        timesheetReport={generatableTimesheetReport}
        onGenerateClick={handleGenerateTimesheetReport}
        onCloseClick={() => {
          dispatch(setGeneratableTimesheetReport(null))
          dispatch(generateTimesheetReportModal())
        }}
      />

      <div className={"page-content"}>
        <MetaTags>
          <title>Timesheet Reports</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Timesheet Reports" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={COLS}
                      data={timesheetReports}
                    >
                      {({ paginationProps, paginationTableProps }) => {
                        return (
                          <ToolkitProvider
                            keyField="id"
                            data={timesheetReports || []}
                            columns={COLS}
                            bootstrap4
                            search
                          >
                            {toolkitProps => {
                              return (
                                <React.Fragment>
                                  <Row className="mb-2">
                                    <Col sm="4">
                                      <h4>Search</h4>
                                    </Col>
                                    <Col sm="8">
                                      <div className="text-sm-end">
                                        <Button
                                          type="button"
                                          color="success"
                                          className="btn-rounded  mb-2 me-2"
                                          onClick={handleCreateTimesheetReport}
                                        >
                                          <i className="mdi mdi-plus me-1" />
                                          New timesheet report
                                        </Button>
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row className="mb-2">
                                    <Filters
                                      params={searchParams}
                                      handleSearch={handleSearch}
                                      handleSearchChange={handleSearchChange}
                                    />
                                  </Row>

                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          noDataIndication={"No Data found"}
                                          defaultSorted={defaultSorted}
                                          classes={"table align-middle table-nowrap"}
                                          keyField="id"
                                          remote={{ search: true, pagination: true }}
                                          {...toolkitProps.baseProps}
                                          onTableChange={handleTableChange}
                                          {...paginationTableProps}
                                          ref={node}
                                        />
                                      </div>

                                    </Col>
                                  </Row>
                                  <Row className="align-items-md-center mt-30">
                                    <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )
                            }}
                          </ToolkitProvider>
                        )
                      }}
                    </PaginationProvider>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

TimesheetReports.propTypes = {
  timesheetReports: PropTypes.array,
  onGetTimesheetReports: PropTypes.func,
  onAddNewTimesheetReport: PropTypes.func,
  onDeleteTimesheetReport: PropTypes.func,
  onUpdateTimesheetReport: PropTypes.func,
};

export default TimesheetReports;
