import React from 'react'
import { Button, Col, Input, Label, Row } from 'reactstrap'
import AsyncSelect from 'react-select/async';
import { getCustomers } from "api/v1";

function Filters({ params, handleSearch, handleSearchChange }) {

  let loadCustomers = (search) => {
    return getCustomers({ search, no_paginate: 1 })
      .then((results = []) => {
        return results
      })
      .catch(({ message }) => {
        if (message !== 'request-cancel') {
          toast.error(message);
        }
      })
  }

  function handleKeyDown(event) {
    if (event.key === "Enter")
      handleSearch()
  }

  return (
    <div className="mb-4">
      <Row>
        <Col sm="2">
          <Label className="form-label">Project Name</Label>
          <Input
            name="search"
            onKeyDown={handleKeyDown}
            type="text"
            onChange={handleSearchChange}
            value={params.search}
          />
        </Col>

        <Col sm="2">
          <Label className="form-label">Project Status</Label>
          <Input
            name="status"
            onKeyDown={handleKeyDown}
            type="select"
            onChange={handleSearchChange}
            value={params.status}
          >
            <option value=""></option>
            <option value="Processing">Processing</option>
            <option value="To Do">To Do</option>
            <option value="Working">Working</option>
            <option value="Complete">Complete</option>
            <option value="Rejected">Rejected</option>
          </Input>
        </Col>

        <Col sm="2">
          <Label className="form-label">Customer</Label>
          <AsyncSelect
            defaultOptions
            placeholder="Select Customer"
            required
            isClearable
            value={params.customer}
            getOptionLabel={({ first_name, last_name }) => `${first_name} ${last_name}`}
            getOptionValue={({ id }) => id}
            loadOptions={loadCustomers}
            onChange={(_customer) => {
              handleSearchChange({ target: { name: 'customer', value: _customer } })
            }}

          />
        </Col>

        <Col sm="4">
          <div className="mt-4">
            <Button
              type="button"
              color="success"
              className="btn-rounded"
              onClick={handleSearch}
            >
              <i className="bx bx-search-alt me-1" />
              Search
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Filters
